import { useQuery } from "react-query";
import { client } from "providers";
import { MuxVideo } from "types/schema";
import { CacheTime } from "constants/cacheTime";

export const moviesQuery = `
    *[_type == "page"][0] { "movies": movies[] { asset-> { playbackId } } }
`;

export const useMovies = () => {
    const { data, isLoading, isError, ...rest } = useQuery<{ movies: MuxVideo[] }>('movies', () => client.fetch(moviesQuery), {
        cacheTime: CacheTime.OneHour
    });

    return {
        movies: data?.movies,
        isLoading,
        isError,
        ...rest
    }
}