import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import { RiWhatsappFill, RiPhoneFill, RiMailFill, RiMapPinUserFill, RiSoundcloudFill } from 'react-icons/ri'


const FooterBig = () => {
  const { t } = useTranslation("common")

  return (
    <div className="grid sm:grid-cols-12 gap-8 py-8 md:py-12">

      {/* 1st block */}
      <div className="sm:col-span-12 lg:col-span-10 lg:max-w-xs">
        <div className="mb-2">
          {/* Logo */}
          <div className="inline-flex text-purple-600 transition duration-150 ease-in-out">
            <Image src="/assets/logo/manezz-white.svg" width={50} height={50} alt="logo" />
          </div>
        </div>
        <div className="text-lg font-bold text-white">{t("general.footer.text")}</div>
      </div>

      <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
        <h6 className="text-base text-slate-200 font-semibold">{t("general.footer.contact")}</h6>
        <ul className="text-sm font-medium space-y-2">
          <li className="text-slate-400 hover:text-purple-600 transition duration-150 ease-in-out">
            <a className="flex gap-2 shrink-0" target="_blank" href={`https://maps.google.com/?q=${t("general.contact.zip")}, ${t("general.contact.city")}`} rel="noreferrer">
              <RiMapPinUserFill size={14} />
              <span className="whitespace-pre-wrap">
                {t("general.contact.zip")}, {t("general.contact.city")}
              </span>
            </a>
          </li>
          <li className="text-slate-400 hover:text-purple-600 transition duration-150 ease-in-out">
            <a className="flex gap-2 items-center shrink-0" href={`mailto:${t("general.contact.email")}`}><RiMailFill size={14} /> {t("general.contact.email")}</a>
          </li>
          <li className="text-slate-400 hover:text-purple-600 transition duration-150 ease-in-out">
            <a className="flex gap-2 items-center shrink-0" href={`tel:${t("general.contact.phone")}`}><RiPhoneFill size={14} /> {t("general.contact.phone")}</a>
          </li>
          <li className="text-slate-400 hover:text-purple-600 transition duration-150 ease-in-out">
            <a className="flex gap-2 items-center shrink-0" target="_blank" href={`https://wa.me/${t("general.contact.phone")}`} rel="noreferrer"><RiWhatsappFill size={14} />{t("general.contact.phone")}</a>
          </li>
        </ul>
      </div>
    </div>
  )
}



const Footer = () => {
  const { t } = useTranslation("common")

  const socialMediaItems = [
    {
      id: "Soundcloud",
      link: t("general.footer.soundcloud.link"),
      Icon: <RiSoundcloudFill size={20} />
    },
    {
      id: "Facebook",
      link: t("general.footer.facebook.link"), Icon: <svg className="w-5 h-5 fill-current" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 10.025C20 4.491 15.52 0 10 0S0 4.491 0 10.025c0 4.852 3.44 8.892 8 9.825v-6.817H6v-3.008h2V7.52a3.508 3.508 0 0 1 3.5-3.509H14v3.008h-2c-.55 0-1 .45-1 1.002v2.005h3v3.008h-3V20c5.05-.501 9-4.772 9-9.975Z" />
      </svg>
    },
    {
      id: "Instagram",
      link: t("general.footer.instagram.link"),
      Icon: <svg className="w-8 h-8 fill-current" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <circle cx="20.145" cy="11.892" r="1" />
        <path d="M16 20c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-6c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2z" />
        <path d="M20 24h-8c-2.056 0-4-1.944-4-4v-8c0-2.056 1.944-4 4-4h8c2.056 0 4 1.944 4 4v8c0 2.056-1.944 4-4 4zm-8-14c-.935 0-2 1.065-2 2v8c0 .953 1.047 2 2 2h8c.935 0 2-1.065 2-2v-8c0-.935-1.065-2-2-2h-8z" />
      </svg>
    },
    {
      id: "Whatsapp",
      link: `https://wa.me/${t("general.contact.phone")}`,
      Icon: <RiWhatsappFill size={20} />
    },
  ]

  return (
    <footer>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <FooterBig />
        {/* Bottom area */}
        <div className="md:flex md:items-center md:justify-between py-6 md:py-8 border-t border-slate-200">

          {/* Social links */}
          <ul className="flex space-x-6 mb-4 md:order-1 md:ml-4 md:mb-0 items-center">
            {socialMediaItems.map(item => (
              <li key={item.id}>
                <a href={item.link} target="_blank" className="text-blue-500 hover:text-purple-600 transition duration-150 ease-in-out" aria-label={item.id} rel="noreferrer">
                  {item.Icon}
                </a>
              </li>
            ))}
          </ul>

          {/* Copyrights note */}
          <div className="text-sm text-slate-400 mr-4">© Manezz. {t("footer.copyright.label")}</div>

        </div>

      </div>
    </footer>
  );
}

export default Footer;
