import { useTranslation } from "next-i18next";
import { FormEvent, useState } from "react";
import { useSubmitForm } from 'queries'
import { IoSend } from 'react-icons/io5'
import { BiLoaderCircle } from 'react-icons/bi'

function ContactForm() {
    const { t } = useTranslation("common")

    const { submitForm, isLoading, isSuccess } = useSubmitForm()

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");

    //   Form validation state
    const [errors, setErrors] = useState<{ [key: string]: boolean }>({});


    const handleValidation = () => {
        let tempErrors: { [key: string]: boolean } = {};
        let isValid = true;

        if (firstName.length <= 0) {
            tempErrors["firstName"] = true;
            isValid = false;
        }

        if (lastName.length <= 0) {
            tempErrors["lastName"] = true;
            isValid = false;
        }
        if (email.length <= 0) {
            tempErrors["email"] = true;
            isValid = false;
        }
        if (message.length <= 0) {
            tempErrors["message"] = true;
            isValid = false;
        }

        setErrors({ ...tempErrors });
        return isValid;
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        let isValidForm = handleValidation();


        if (isValidForm) {
            submitForm({
                formData: {
                    email: email,
                    subject: subject,
                    firstName: firstName,
                    lastName: lastName,
                    message: message,
                }
            })
        }
    };


    return (
        <form className="max-w-xl mx-auto" onSubmit={(e) => handleSubmit(e)} name="contact" method="POST">
            {isSuccess ? (
                <div className="bg-teal-100 border-t-4 border-teal-500 rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert">
                    <div className="flex">
                        <div className="py-1"><svg className="fill-current h-6 w-6 text-teal-500 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg></div>
                        <div>
                            <p className="font-bold">{t("general.contact.form.success.title")}</p>
                            <p className="text-sm">{t("general.contact.form.success.description")}</p>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="flex flex-wrap -mx-3 mb-4">
                        <div className="w-full md:w-1/2 px-3 mb-4 md:mb-0">
                            <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="first-name">{t("general.contact.form.first_name.label")} <span className="text-red-600">*</span></label>
                            <input onChange={(e) => setFirstName(e.target.value)} style={{ backgroundColor: 'transparent' }} id="first-name" type="text" className={`form-input w-full text-gray-300 ${errors['firstName'] && 'border-red-500 focus:border-red-500'}`} placeholder={t("general.contact.form.first_name.placeholder")} required />
                            {errors['fistName'] && <p className="text-red-500 text-sm mt-2">{t("general.form.error.required")}</p>}
                        </div>
                        <div className="w-full md:w-1/2 px-3">
                            <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="last-name">{t("general.contact.form.name.label")} <span className="text-red-600">*</span></label>
                            <input onChange={(e) => setLastName(e.target.value)} style={{ backgroundColor: 'transparent' }} id="last-name" type="text" className={`form-input w-full text-gray-300 ${errors['lastName'] && 'border-red-500 focus:border-red-500'}`} placeholder={t("general.contact.form.name.placeholder")} required />
                            {errors['lastName'] && <p className="text-red-500 text-sm mt-2">{t("general.form.error.required")}</p>}
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-4">
                        <div className="w-full px-3">
                            <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="email">{t("general.contact.form.email.label")} <span className="text-red-600">*</span></label>
                            <input onChange={(e) => setEmail(e.target.value)} style={{ backgroundColor: 'transparent' }} id="email" type="email" className={`form-input w-full text-gray-300 ${errors['email'] && 'border-red-500 focus:border-red-500'}`} placeholder={t("general.contact.form.email.placeholder")} required />
                            {errors['email'] && <p className="text-red-500 text-sm mt-2">{t("general.form.error.required")}</p>}
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-4">
                        <div className="w-full px-3">
                            <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="subject">{t("general.contact.form.subject.label")} <span className="text-red-600">*</span></label>
                            <input onChange={(e) => setSubject(e.target.value)} style={{ backgroundColor: 'transparent' }} id="subject" type="text" className={`form-input w-full text-gray-300 ${errors['subject'] && 'border-red-500 focus:border-red-500'}`} placeholder={t("general.contact.form.subject.placeholder")} required />
                            {errors['subject'] && <p className="text-red-500 text-sm mt-2">{t("general.form.error.required")}</p>}
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-4">
                        <div className="w-full px-3">
                            <label className="block text-gray-300 text-sm font-medium mb-1" htmlFor="message">{t("general.contact.form.message.label")} <span className="text-red-600">*</span></label>
                            <textarea onChange={(e) => setMessage(e.target.value)} id="message" rows={4} className={`form-textarea w-full text-gray-300 bg-transparent ${errors['message'] && 'border-red-500 focus:border-red-500'}`} placeholder={t("general.contact.form.message.placeholder")}></textarea>
                            {errors['message'] && <p className="text-red-500 text-sm mt-2">{t("general.form.error.required")}</p>}
                        </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mt-6">
                        <div className="w-full px-3">
                            <button type="submit" disabled={isSuccess || isLoading} className={`bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 rounded-full w-full btn text-white group`}>
                                {isLoading ? t("general.contact.form.submit.loading.label") : t("general.contact.form.submit.label")}
                                <span className={`ml-4 ${isLoading && "animate-spin"}`}>
                                    {isLoading ?
                                        <BiLoaderCircle color="white" size={14} /> : <IoSend color="white" size={14} />
                                    }
                                </span>
                            </button>
                        </div>
                    </div>
                </>
            )}
        </form >
    );
}

export default ContactForm;