import { useTranslation } from "next-i18next";
import Section from "components/@section/Section";
import ContactForm from "./Form";

function Contact() {
    const { t } = useTranslation("common")
    return (
        <Section id="contact" title={t("home.section.contact.title")} withSeparator>
            <ContactForm />
        </Section >
    );
}

export default Contact;
