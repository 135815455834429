import type { NextPage } from 'next'
import Head from 'next/head'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next'
import { Header, Footer, Timeline } from 'components'
import HeroBanner from '@/components/@common/Hero'
import { client, imageUrlBuilder } from 'providers'
import { pageDetailQuery, useMovies, useTracks } from 'queries'
import { Page } from 'types/schema'
import Contact from '@/components/@contact/Contact'
import Section from '@/components/@section/Section'
import Image from 'next/image'
import About from '@/components/@common/FeaturesHome'
import { references } from 'constants/references'

const Home: NextPage<{ data: Page }> = ({ data }) => {
  const { t } = useTranslation('common');

  const { bannerMovie, profilePicture } = data


  const { movies, isLoading } = useMovies();
  const { tracks } = useTracks();

  return (
    <div className="flex flex-col min-h-screen overflow-hidden bg-slate-900">
      <Head>
        <title>{t("meta.title")}</title>
        <meta name="description" content={t("meta.description")} />
        <meta name="keywords" content="manezz, dj, antwerpen, antwerp, drums, drummen, hybrid, regio, bookings, tomorrowland, extrema, xo, outdoor"></meta>
        <link rel="icon" href="/favicon.svg" />
      </Head>

      <Header />

      <HeroBanner playbackId={bannerMovie.playbackId} />

      <main className="grow mb-10">
        <section id="movies" className="mt-10 flex-wrap-reverse	max-w-4xl mx-auto flex gap-4 justify-center items-center">
          {!isLoading ? (<>
            {(movies ?? []).map((movie, i) => (
              <div className='flex-1' style={{ minWidth: 250 }} key={`${movie.asset.playbackId} + ${i}`}>
                {/* @ts-ignore */}
                <mux-video style={{ width: "100%", height: "100%", objectFit: 'cover' }} playback-id={movie.asset.playbackId} poster={`https://image.mux.com/${movie.asset.playbackId}/thumbnail.png`} controls slot="media" />
              </div>
            ))}
          </>) : (
            <>
              {Array.from({ length: 4 }).map((_, i) => (
                <div style={{ flex: 1, minWidth: '31%' }} className="animate-pulse h-40 bg-slate-800" key={`${i}`} />
              ))}
            </>
          )}
        </section>
        <Section id="references">
          <section className="flex flex-wrap gap-10 opacity-50 w-50 items-center justify-center">
            {references.map(reference => (
              // @ts-ignore
              <Image key={reference.alt} src={reference.url} width="150" height="75" alt={reference.alt} />
            ))}
          </section>
        </Section>

        <Section id="about">
          <About
            blurImageUrl={imageUrlBuilder.image(profilePicture).height(50).width(50).quality(10).url()}
            profilePicture={imageUrlBuilder.image(profilePicture).height(520).width(540).url()}
          />
        </Section>

        {(tracks ?? []).length >= 1 && (
          <Section withSeparator id="tracks" title={t("home.section.tracks.title")}>
            <section className="max-w-3xl mx-auto">
              <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="flex gap-4 flex-col">
                  {(tracks ?? []).map((id: string) => {
                    const trackUrl = `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/${id}&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true`
                    return (
                      <div key={id}>
                        <iframe
                          width="100%"
                          height="166"
                          scrolling="no"
                          frameBorder="no"
                          allow="autoplay"
                          src={trackUrl}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            </section>
          </Section>
        )
        }

        {/* <Section id="events" title={t("home.section.events.title")} withSeparator>
          <Timeline />
        </Section> */}
        <Contact />
      </main >
      <Footer />

    </div >
  )
}

export const getStaticProps = async ({ locale }: { locale: string }) => {
  // Fetch data from external API
  const data = await client.fetch(pageDetailQuery, { slug: 'home' })

  // Pass data to the page via props
  return { props: { data, ...await serverSideTranslations(locale, ['common']), } }
}


export default Home
