// client.js
import sanityClient from '@sanity/client'
import ImageUrlBuilder from '@sanity/image-url';

export const client = sanityClient({
    projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID, // you can find this in sanity.json
    dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
    token: process.env.SANITY_API_TOKEN,
    apiVersion: "1",

    // or the name you chose in step 1
    useCdn: false // `false` if you want to ensure fresh data
})

export const imageUrlBuilder = ImageUrlBuilder(client);

export default client;