import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import React from 'react';

const About = ({ profilePicture, blurImageUrl }: { profilePicture: string, blurImageUrl: string }) => {
  const { t } = useTranslation("common")

  return (
    <section>
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-800">

          {/* Items */}
          <div className="grid gap-20" data-aos-id-features-home>

            {/* Item */}
            <div className="md:grid md:grid-cols-12 md:gap-6 items-center">
              {/* Image */}
              <div className="max-w-xl md:max-w-md md:w-full mx-auto md:col-span-5 lg:col-span-6 mb-8 md:mb-0 md:order-1" data-aos="fade-up" data-aos-anchor="[data-aos-id-features-home]">
                <div className="relative about-image">
                  <div className="md:max-w-none rounded-full overflow-hidden">
                    <Image placeholder='blur' blurDataURL={blurImageUrl} src={profilePicture} width="540" height="520" alt="Picture of Manezz" />
                  </div>
                </div>
              </div>
              {/* Content */}
              <div className="max-w-xl md:max-w-none md:w-full mx-auto md:col-span-7 lg:col-span-6" data-aos="fade-right" data-aos-anchor="[data-aos-id-features-home]">
                <div className="md:pr-4 lg:pr-12 xl:pr-16">
                  <div className="font-architects-daughter text-xl text-blue-600 mb-2">{t("home.section.about.subtitle")}</div>
                  <h3 className="h3 mb-3 text-white">{t("home.section.about.title")}</h3>
                  <p className="whitespace-pre-wrap text-xl text-gray-400 mb-4">{t("home.section.about.description")}</p>
                  <ul className="flex flex-wrap text-lg text-gray-400 -mx-2 -my-1">
                    {Array.from({ length: 4 }).map((_, i) => (
                      <li key={i} className="flex items-center mx-2 my-1">
                        <svg className="w-3 h-3 fill-current text-green-500 mr-2 shrink-0" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                          <path d="M10.28 2.28L3.989 8.575 1.695 6.28A1 1 0 00.28 7.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 2.28z" />
                        </svg>
                        <span>{t(`home.section.about.item_${i + 1}`)}</span>
                      </li>
                    ))}
                  </ul>
                  <div className="flex items-start mt-8">
                    <div>
                      <blockquote className="text-gray-400 italic mb-3">{t("home.section.about.quote")}</blockquote>
                      <div className="text-gray-700 font-medium">
                        <cite className="text-gray-200 not-italic">— Manezz —</cite>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </section>
  );
}

export default About;
