import { useQuery } from "react-query";
import { client } from "providers";
import { CacheTime } from "constants/cacheTime";

export const tracksQuery = `
    *[_type == "page"][0] { tracks }
`;

export const useTracks = () => {
    const { data, isLoading, isError, ...rest } = useQuery<{ tracks: string[] }>('tracks', () => client.fetch(tracksQuery), {
        cacheTime: CacheTime.OneHour
    });

    return {
        tracks: data?.tracks,
        isLoading,
        isError,
        ...rest
    }
}