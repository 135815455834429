import Link from 'next/link'
import { ImArrowRight2 as Icon } from 'react-icons/im';

type Props = {
    to: string,
    label: string
    icon?: JSX.Element
}

const Button = ({ to, label, icon = <Icon size={14} color="white" /> }: Props) => {
    return (
        <Link passHref href={to}>
            <button className={`mt-5 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 rounded-full btn text-white group opacity-60 hover:opacity-100`}>
                {label}
                <span className={`ml-4`}>
                    {icon}
                </span>
            </button>
        </Link>
    )
}
export default Button