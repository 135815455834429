import { useMutation } from 'react-query';

export const useSubmitForm = () => {
    const { data, mutate, ...rest } = useMutation<
        {},
        {},
        {
            formData: {
                email: string,
                subject: string,
                firstName: string,
                lastName: string,
                message: string
            }
        }
    >(['addressValidate'], ({ formData }) => {
        return fetch("/api/sendgrid", {
            body: JSON.stringify({
                email: formData.email,
                subject: formData.subject,
                firstName: formData.firstName,
                lastName: formData.lastName,
                message: formData.message,
            }),
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST",
        });
    });

    return {
        submitForm: mutate,
        ...rest,
    };
};