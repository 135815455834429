import { useTranslation } from "next-i18next";
import { useEffect, useState, useRef } from "react";
import { MdArrowDownward } from "react-icons/md";
import { GoMute, GoUnmute, GoArrowRight } from "react-icons/go";
import { GiPauseButton, GiPlayButton } from "react-icons/gi";
import "@mux-elements/mux-video";
import Link from "next/link";
import Button from "./Button";

const HeroBanner = ({
  playbackId,
  type = "default",
}: {
  playbackId: string;
  type?: "404" | "default";
}) => {
  const { t } = useTranslation("common");
  const videoRef = useRef<HTMLVideoElement>();

  const [muted, setMuted] = useState(true);
  const [paused, setPaused] = useState(false);

  useEffect(() => {
    if (paused) {
      // @ts-ignore
      videoRef.current?.pause();
    } else {
      // @ts-ignore
      videoRef.current
        ?.play()
        .then()
        .catch((e) => console.log(e));
    }
  }, [paused]);

  useEffect(() => {
    // @ts-ignore
    videoRef.current.muted = muted;
  }, [muted]);

  return (
    <section className="relative w-screen h-screen hero-banner-big text-center relative w-screen h-screen flex items-center justify-center bg-slate-900">
      {/* @ts-ignore */}
      <mux-video
        id="banner-video"
        class="masked"
        style={{
          position: "absolute",
          opacity: 0.8,
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
        playback-id={playbackId}
        loop
        playsinline
        autoplay
        slot="media"
        poster={`https://image.mux.com/${playbackId}/thumbnail.jpg`}
        ref={videoRef}
      />
      <div className="relative z-10">
        <h1 className="h1 text-white relative">
          {type === "404"
            ? "404 - Page not found"
            : t("home.section.hero.title")}
        </h1>
        <Button
          to={type === "404" ? "/" : "#movies"}
          label={t("general.hero.cta.label")}
        />
      </div>
      <div className="z-10 animate-bounce absolute left-1/2 bottom-10 w-12 h-12 bg-transparent flex -ml-5 items-center justify-center opacity-75">
        <MdArrowDownward size={24} fill="#8E9CD3" />
      </div>
      <div
        onClick={() => setPaused(!paused)}
        className={`hidden md:flex z-10 cursor-pointer absolute right-20 bottom-10 w-12 h-12 bg-transparent -ml-5 items-center justify-center ${
          muted ? "opacity-85" : "opacity-100"
        }`}
      >
        {!paused ? (
          <GiPauseButton size={24} fill="#8E9CD3" />
        ) : (
          <GiPlayButton size={24} fill="#8E9CD3" />
        )}
      </div>
      <div
        onClick={() => setMuted(!muted)}
        className={`hidden md:flex z-10 cursor-pointer absolute right-10 bottom-10 w-12 h-12 bg-transparent -ml-5 items-center justify-center ${
          muted ? "opacity-85" : "opacity-100"
        }`}
      >
        {muted ? (
          <GoMute size={24} fill="#8E9CD3" />
        ) : (
          <GoUnmute size={24} fill="#8E9CD3" />
        )}
      </div>
    </section>
  );
};

export default HeroBanner;
