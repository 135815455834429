const Section: React.FC<{
    title?: string,
    description?: string,
    withSeparator?: boolean,
    id: string,
    bg?: string
}> = ({ id, title, description, withSeparator = false, bg = 'transparent', children }) => {
    return <section className={bg}>
        <div id={id} className="max-w-6xl mx-auto px-4 sm:px-6">
            <div className={`py-10 md:py-16 ${withSeparator && "border-t border-gray-800"}`}>
                {/* Section header */}
                {title && (
                    <div className="max-w-3xl mx-auto text-center pb-6 md:pb-12">
                        <h2 className="h2 text-white mb-4" data-aos="fade-up">{title}</h2>
                        {description && (
                            <p className="text-xl text-gray-400" data-aos="fade-up" data-aos-delay="200">
                                {description}
                            </p>
                        )
                        }
                    </div>
                )}
                {children}
            </div>
        </div>
    </section>
}

export default Section